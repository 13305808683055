import React, {
  useState, useRef, useEffect,
} from 'react';
import {
  Formik, Field, Form, ErrorMessage,
} from 'formik';
import cx from 'classnames';
import useFade from '@hooks/useFade';
import { formValidators } from './validation';
import * as styles from './SignUpForm.module.scss';

const getAsField = (fieldType) => {
  let asField;
  switch (fieldType) {
    case 'select':
      asField = 'select';
      break;
    case 'textarea':
      asField = 'textarea';
      break;
    default:
      asField = 'input';
  }
  return asField;
};

const encode = (data) => Object.keys(data)
  .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
  .join('&');

const SignUpForm = ({
  fields, formId, submitButtonLabel, id, hiddenFormHtml, postSubmitMessage,
}) => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [googleEntrySubmitted, setGoogleEntrySubmitted] = useState(false);
  const [isFading, hasFaded] = useFade((formSubmitted && googleEntrySubmitted), 500);
  const formRef = useRef();

  useEffect(() => {
    if (hasFaded) {
      const timer = setTimeout(() => {
        if (formRef.current) {
          const { offsetTop, offsetHeight } = formRef.current;
          window.scrollBy({
            top: -(offsetTop + offsetHeight),
            left: 0,
            behavior: 'smooth',
          });
        }
      }, 550);
      return () => clearTimeout(timer);
    }
    return () => {};
  }, [hasFaded]);

  if (!fields || !formId) {
    return <React.Fragment />;
  }

  const initialValues = {};
  // eslint-disable-next-line no-unused-vars
  let emailFieldName = '';
  // eslint-disable-next-line no-unused-vars
  let mobileFieldName = '';
  fields.forEach((field) => {
    initialValues[field.name] = '';
    if (field.type === 'email') {
      emailFieldName = field.name;
    }
    if (field.type === 'tel') {
      mobileFieldName = field.name;
    }
  });

  return (
    <div
      id={id}
      className={cx(styles.signupform__outerWrapper)}
    >
      {hiddenFormHtml && (
        <div
            // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: hiddenFormHtml,
          }}
        />
      )}
      {!hasFaded && (
        <Formik
          initialValues={initialValues}
          onSubmit={async (values, actions) => {
            const currentDate = (new Date(Date.now())).toISOString();
            fetch('/', {
              method: 'POST',
              headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
              body: encode({ 'form-name': formId, ...values }),
            })
              .then((resp) => {
                if (!resp.ok) {
                  console.error(resp);
                }
              })
              .catch((error) => {
                console.error(error);
              })
              .finally(() => {
                setFormSubmitted(true);
              });

            fetch('https://script.google.com/macros/s/AKfycbzXklwagJFBpL-kQoYsTch0j201tFS5SSB8e_TNZEx4TuIOXTw/exec', {
              method: 'POST',
              headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
              body: encode({ DateTimestampSubmitted: currentDate, ...values }),
            })
              .then((resp) => {
                if (!resp.ok) {
                  console.error(resp);
                }
              })
              .catch((error) => {
                console.error(error);
              })
              .finally(() => {
                setGoogleEntrySubmitted(true);
              });

            if (googleEntrySubmitted && formSubmitted) {
              actions.setSubmitting(false);
              actions.resetForm();
            }
          }}
          validate={() => ({})}
        >
          {({ errors, touched, values }) => (
            <Form
              action={formId}
              data-netlify
              name={formId}
              className={cx(styles.signupform, {
                [styles.signupformIsFading]: isFading,
              })}
            >
              <Field
                id="hidden-field"
                name="form-name"
                type="hidden"
                as="input"
                value={formId}
              />
              {fields.map((field) => {
                const {
                  type, name, desktopWidth, mobileWidth, required, id: fieldId,
                  label, errorMessage, placeholder, options, dependency,
                } = field;
                const asField = getAsField(type);
                const validationType = required ? 'required' : 'optional';
                const validationFunction = formValidators[type]
                      && formValidators[type][validationType];
                const hasError = errors[name] && touched[name];
                let visible = !(dependency && dependency.name);
                if (values && dependency) {
                  const { name: fieldName, value } = dependency;
                  visible = values[fieldName] && (
                    values[fieldName] === value || values[fieldName][0] === value
                  );
                }

                if (type === 'email' || type === 'text' || type === 'tel' || type === 'textarea' || type === 'postalcode') {
                  return (
                    <React.Fragment>
                      <div
                        key={fieldId}
                        className={cx(styles.signupform__fieldWrapper, {
                          [styles[`signupform__fieldWrapperDesktop${desktopWidth}`]]: desktopWidth,
                          [styles[`signupform__fieldWrapperMobile${mobileWidth}`]]: mobileWidth,
                          [styles.signupform__fieldWrapperHidden]: !visible,
                        })}
                      >
                        <Field
                          id={fieldId}
                          name={name}
                          type={type}
                          as={asField}
                          validate={(value) => {
                            if (typeof validationFunction === 'function') {
                              return validationFunction(value, label, errorMessage);
                            }
                            return '';
                          }}
                          aria-describedby={`${fieldId}-error`}
                          className={cx(styles.signupform__formcontrol, styles[`signupform__field${(asField)}`], {
                            [styles[`signupform__field${(asField)}HasError`]]: hasError,
                          })}
                          placeholder={placeholder}
                        />
                        <label
                          htmlFor={fieldId}
                          className={cx(styles.signupform__label)}
                        >
                          {label}
                          {required && (
                          <span className={styles.signupform__required}> *</span>
                          )}
                        </label>
                        <span
                          className={styles.signupform__errorMessage}
                          aria-live="assertive"
                          id={`${fieldId}-error`}
                        >
                          <ErrorMessage name={name} />
                        </span>
                      </div>
                    </React.Fragment>
                  );
                }
                if (type === 'select') {
                  return (
                    <div
                      className={cx(
                        styles.signupform__fieldWrapper,
                        styles[`signupform__fieldWrapper${asField}`], {
                          [styles.signupform__fieldWrapperHasError]: hasError,
                          [styles[`signupform__fieldWrapperDesktop${desktopWidth}`]]: desktopWidth,
                          [styles[`signupform__fieldWrapperMobile${mobileWidth}`]]: mobileWidth,
                          [styles.signupform__fieldWrapperHidden]: !visible,
                        },
                      )}
                      key={fieldId}
                    >
                      <label
                        className={cx(styles.signupform__label)}
                        htmlFor={fieldId}
                      >
                        {label}
                        {required && (
                        <span className={styles.signupform__required}> *</span>
                        )}
                      </label>
                      <Field
                        id={fieldId}
                        name={name}
                        type={type}
                        as={asField}
                        aria-describedby={`${fieldId}-error`}
                        className={cx(styles[`signupform__field${asField}`], {
                          [styles[`signupform__field${asField}HasError`]]: hasError,
                        })}
                        validate={(value) => {
                          if (typeof validationFunction === 'function') {
                            return validationFunction(value, label, errorMessage);
                          }
                          return '';
                        }}
                      >
                        <option value="none">{placeholder || 'Select an option'}</option>
                        {options && options.map((option) => (
                          <option value={option.value}>{option.key}</option>
                        ))}
                      </Field>
                      <span
                        className={styles.signupform__errorMessage}
                        aria-live="assertive"
                        id={`${fieldId}-error`}
                      >
                        <ErrorMessage name={name} />
                      </span>
                    </div>
                  );
                }
                if (type === 'checkbox') {
                  return (
                    <div
                      key={fieldId}
                      role="group"
                      className={cx(styles.signupform__fieldWrapper, {
                        [styles[`signupform__fieldWrapperDesktop${desktopWidth}`]]: desktopWidth,
                        [styles[`signupform__fieldWrapperMobile${mobileWidth}`]]: mobileWidth,
                      })}
                    >
                      <label
                        className={cx(styles.signupform__label,
                          `field-group-label-${type}`, {
                            [styles.signupform__labelHasError]: hasError,
                            [styles.signupform__labelHidden]: options.length === 1,
                          })}
                        htmlFor={fieldId}
                      >
                        {label}
                        {required && (
                        <span className={styles.signupform__required}> *</span>
                        )}
                      </label>
                      {options && options.map((option) => (
                        <label
                          htmlFor={`${fieldId}-${option.value}`}
                          className={cx(styles.signupform__checkboxLabel,
                            `${styles.signupform__label}-${field.type}`, {
                              [`${styles.signupform__label}-${field.type}HasError`]: hasError,
                            })}
                        >
                          {option.key}
                          <Field
                            type={type}
                            name={name}
                            value={option.value}
                            className={cx(styles[`signupform__field${type}`], {
                              [styles[`signupform__field${type}HasError`]]: hasError,
                            })}
                            as={asField}
                            validate={(value) => {
                              if (typeof validationFunction === 'function') {
                                return validationFunction(value, label, errorMessage);
                              }
                              return '';
                            }}
                            aria-describedby={`${fieldId}-error`}
                          />
                          <span className={styles.signupform__checkmark} />
                        </label>
                      ))}
                      <span
                        className={styles.signupform__errorMessage}
                        aria-live="assertive"
                        id={`${fieldId}-error`}
                      >
                        <ErrorMessage name={name} />
                      </span>
                    </div>
                  );
                }
                return (
                  <React.Fragment>
                    <div
                      key={fieldId}
                      className={cx(styles.signupform__fieldWrapper, {
                        [styles[`signupform__fieldWrapperDesktop${desktopWidth}`]]: desktopWidth,
                        [styles[`signupform__fieldWrapperMobile${mobileWidth}`]]: mobileWidth,
                        [styles.signupform__fieldWrapperHidden]: !visible,
                      })}
                    >
                      <Field
                        id={fieldId}
                        name={name}
                        type={type}
                        as={asField}
                        validate={(value) => {
                          if (typeof validationFunction === 'function') {
                            return validationFunction(value, label, errorMessage);
                          }
                          return '';
                        }}
                        aria-describedby={`${fieldId}-error`}
                        className={cx(styles.signupform__formcontrol, styles[`signupform__field${(asField)}`], {
                          [styles[`signupform__field${(asField)}HasError`]]: hasError,
                        })}
                        placeholder={placeholder}
                      />
                      <label
                        htmlFor={fieldId}
                        className={cx(styles.signupform__label)}
                      >
                        {label}
                        {required && (
                          <span className={styles.signupform__required}> *</span>
                        )}
                      </label>
                      <span
                        className={styles.signupform__errorMessage}
                        aria-live="assertive"
                        id={`${fieldId}-error`}
                      >
                        <ErrorMessage name={name} />
                      </span>
                    </div>
                  </React.Fragment>
                );
              })}
              <div
                className={cx(styles.signupform__fieldWrapper,
                  styles.signupform__fieldWrapperSubmit,
                  styles.signupform__fieldWrapperDesktopAuto,
                  styles.signupform__fieldWrapperMobileAuto)}
              >
                <button
                  className={cx(styles.signupform__submit, {
                  })}
                  type="submit"
                >
                  {submitButtonLabel || 'Submit'}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      )}
      {hasFaded && postSubmitMessage && (
        <div
            // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: postSubmitMessage,
          }}
        />
      )}
    </div>
  );
};

export default SignUpForm;
