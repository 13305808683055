// extracted by mini-css-extract-plugin
export var plansection = "PlanSection-module--plansection--3HkVw";
export var plansection__btpattern = "PlanSection-module--plansection__btpattern--3iLYC";
export var plansection__image = "PlanSection-module--plansection__image--1L9dF";
export var plansection__cards = "PlanSection-module--plansection__cards--1Gf5O";
export var plansection__card = "PlanSection-module--plansection__card--31J1Y";
export var plansection__cardtitle = "PlanSection-module--plansection__cardtitle--FZFoX";
export var plansection__icon = "PlanSection-module--plansection__icon--TSD_S";
export var plansection__card1 = "PlanSection-module--plansection__card--1--24JT7";
export var plansection__card2 = "PlanSection-module--plansection__card--2--3wie7";
export var plansection__card3 = "PlanSection-module--plansection__card--3--EZJVy";
export var plansection__card4 = "PlanSection-module--plansection__card--4--D1uXn";
export var fadeOutAnimation = "PlanSection-module--fadeOutAnimation--u_hoc";
export var fadeInAnimation = "PlanSection-module--fadeInAnimation--2A8h1";