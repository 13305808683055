import React from 'react';
import classnames from 'classnames';
import { canGoNext } from './utils/innerSliderUtils';
import * as styles from './Slider.module.scss';
import Chevron from '../../assets/chevron.inline.svg';

export default class NextArrow extends React.PureComponent {
  clickHandler(options, e) {
    if (e) {
      e.preventDefault();
    }
    this.props.clickHandler(options, e);
  }

  render() {
    const nextClasses = {
      [styles.slider__arrow]: true,
      [styles.slider__arrowNext]: true,
    };
    let nextHandler = this.clickHandler.bind(this, { message: 'next' });

    if (!canGoNext(this.props)) {
      [nextClasses[styles.slider__arrowDisabled]] = true;
      nextHandler = null;
    }

    const nextArrowProps = {
      key: '1',
      'data-role': 'none',
      className: classnames(nextClasses),
      style: { display: 'block' },
      onClick: nextHandler,
    };
    const customProps = {
      currentSlide: this.props.currentSlide,
      slideCount: this.props.slideCount,
    };
    let nextArrow;

    if (this.props.nextArrow) {
      nextArrow = React.cloneElement(this.props.nextArrow, {
        ...nextArrowProps,
        ...customProps,
      });
    } else {
      nextArrow = (
        <button key="next-prev" type="button" {...nextArrowProps}>
          <Chevron />
          {' '}
          Next
        </button>
      );
    }

    return nextArrow;
  }
}
