import React from 'react';
import cx from 'classnames';
import { GatsbyImage } from 'gatsby-plugin-image';
import SVG from 'react-inlinesvg';
import Gutters from '@components/Gutters';
import MaxContent from '@components/MaxContent';
import SectionTitle from '@components/SectionTitle';
import SectionIntro from '@components/SectionIntro';
import getImageData from '@hooks/getImageData';
import * as styles from './PlanSection.module.scss';

const PlanSection = (props) => {
  const {
    title, intro, plans,
  } = props;

  const finalPlans = [];

  if (plans) {
    plans.forEach((plan) => {
      const { photo } = plan;
      const [planImage] = getImageData({ imageFile: photo });

      finalPlans.push({
        ...plan,
        photo: planImage,
      });
    });
  }

  const planCount = finalPlans.length;

  return (
    <section className={styles.plansection} aria-label={title} role="contentinfo">
      <div className={styles.plansection__btpattern} />
      <MaxContent>
        <Gutters>
          {title && (
            <SectionTitle>{title}</SectionTitle>
          )}
          {intro && (
            <SectionIntro intro={intro} align="center" />
          )}
          {finalPlans && (
            <ul className={styles.plansection__cards}>
              {finalPlans.map((plan) => {
                const {
                  icon, photo, name, alt,
                } = plan;
                return (
                  <li
                    key={name}
                    className={cx(styles.plansection__card, styles[`plansection__card${(planCount)}`])}
                  >
                    {photo && photo.node && (
                      <GatsbyImage
                        className={cx(styles.plansection__image)}
                        image={photo.node.gatsbyImageData}
                        alt={alt}
                      />
                    )}
                    <div className={cx(styles.plansection__icon)}>
                      <SVG src={icon} title={name} />
                    </div>
                    {name && (
                      <p className={cx(styles.plansection__cardtitle)}>{name}</p>
                    )}
                  </li>
                );
              })}
            </ul>
          )}
        </Gutters>
      </MaxContent>
    </section>
  );
};

export default PlanSection;
