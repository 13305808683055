import * as React from "react"
import { graphql } from "gatsby"
import Layout from "@components/Layout"
import Seo from "@components/Seo"
import Hero from "@components/Hero"
import PlanSection from "@components/PlanSection"
import SystemSection from "@components/SystemSection"
import ResourceSection from "@components/ResourceSection"
import Popup from "@components/Popup"
import getImageData from "@hooks/getImageData"

const BlogIndex = ({ data, location }) => {
  const { site, pageContent, popup } = data || {}
  const siteTitle = site.siteMetadata.title || "Title"
  const { popupRemark } = popup || []
  const { childrenMarkdownRemark } = pageContent || []
  const {
    heroTitle,
    heroImage,
    heroButtons,
    heroImageAlt,
    formHeader,
    heroFormSetup,
    planHeader,
    plans,
    systemHeader,
    systems,
    resourceHeader,
    slideshow,
    resourceSidebar,
    resourceSidebarButtonLink,
    resourceSidebarButtonText,
    footerLinks,
    footerCredits,
    metaImage,
    pageBanner,
  } = (childrenMarkdownRemark[0] && childrenMarkdownRemark[0].frontmatter) || {}
  const {
    heroIntroHtml,
    planIntroHtml,
    resourceIntroHtml,
    systemIntroHtml,
    footerTextHtml,
    bannerTextHtml,
    postSubmitMessageHtml,
  } = (childrenMarkdownRemark[0] && childrenMarkdownRemark[0].fields) || {}
  const { popupEnable, popupImage, popupImageAltText } =
    (popupRemark && popupRemark.frontmatter) || {}
  const { popupBodyHtml, popupCtaBodyHtml } =
    (popupRemark && popupRemark.fields) || {}
  const [finalHeroImage] = getImageData({ imageFile: heroImage })

  const footerProps = {
    links: footerLinks,
    credits: footerCredits,
    text: footerTextHtml,
  }

  const bannerProps = {
    bannerTextHtml,
    ...pageBanner,
  }

  const popupProps = {
    popupEnable,
    popupImage,
    popupImageAltText,
    popupBodyHtml,
    popupCtaBodyHtml,
  }

  return (
    <Layout
      location={location}
      title={siteTitle}
      footerProps={footerProps}
      bannerProps={bannerProps}
    >
      <Seo title="" metaImage={metaImage} />
      {popupEnable && <Popup {...popupProps} />}
      <Hero
        title={heroTitle}
        intro={heroIntroHtml}
        ctas={heroButtons}
        image={finalHeroImage}
        formHeader={formHeader}
        formSetup={heroFormSetup}
        alt={heroImageAlt}
        postFormSubmit={postSubmitMessageHtml}
      />
      <PlanSection title={planHeader} intro={planIntroHtml} plans={plans} />
      <SystemSection
        title={systemHeader}
        intro={systemIntroHtml}
        systems={systems}
      />
      <ResourceSection
        title={resourceHeader}
        intro={resourceIntroHtml}
        slideshow={slideshow}
        sidebar={resourceSidebar}
        buttonlink={resourceSidebarButtonLink}
        buttontext={resourceSidebarButtonText}
      />
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    popup: file(
      sourceInstanceName: { eq: "content" }
      name: { eq: "popup-settings" }
    ) {
      id
      name
      popupRemark: childMarkdownRemark {
        frontmatter {
          popupImage: popup_image
          popupEnable: popup_enable
          popupImageAltText: popup_image_alt_text
        }
        fields {
          popupBodyHtml: popup_body_html
          popupCtaBodyHtml: popup_cta_body_html
        }
      }
    }
    pageContent: file(
      sourceInstanceName: { eq: "content" }
      name: { eq: "home" }
    ) {
      id
      childrenMarkdownRemark {
        fields {
          heroIntroHtml: hero_intro_html
          planIntroHtml: plan_intro_html
          resourceIntroHtml: resource_intro_html
          systemIntroHtml: system_intro_html
          bannerTextHtml: banner_text_html
          footerTextHtml: footer_text_html
          postSubmitMessageHtml: post_submit_message_html
        }
        frontmatter {
          heroTitle: hero_title
          heroIntro: hero_intro
          heroImage: hero_image
          heroButtons: hero_buttons {
            buttonText: button_text
            link
          }
          heroImageAlt: hero_image_alt_text
          formHeader: form_header
          heroFormSetup: hero_form_setup {
            formId: form_id
            submitButtonLabel: submit_button_label
            fields: form_fields {
              errorMessage: error_message
              desktopWidth: field_desktop_width
              id: field_id
              label: field_label
              mobileWidth: field_mobile_width
              name: field_name
              placeholder: field_placeholder
              required: field_required
              type: field_type
              dependency: field_dependency {
                name: dependent_field_name
                value: dependent_field_value
              }
              options: field_options {
                key
                value
              }
            }
          }
          pageBanner: page_banner {
            bannerEndTime: banner_end_time
            bannerStartTime: banner_start_time
          }
          planHeader: plan_header
          planIntro: plan_intro
          plans {
            icon
            name
            photo
            alt: photo_alt_text
          }
          systemHeader: system_header
          systemIntro: system_intro
          systems {
            name
            photo
            alt: photo_alt_text
          }
          resourceHeader: resource_header
          resourceIntro: resource_intro
          resourceSidebar: resource_sidebar
          resourceSidebarButtonLink: resource_sidebar_button_link
          resourceSidebarButtonText: resource_sidebar_button_text
          slideshow {
            slide
            alt: photo_alt_text
          }
          metaImage: meta_image
          footerLinks: footer_links {
            linkText: link_text
            linkUrl: link_url
          }
          footerCredits: footer_credits {
            credit
          }
        }
      }
    }
  }
`
