import React from 'react';
import classnames from 'classnames';
import * as styles from './Slider.module.scss';
import Chevron from '../../assets/chevron.inline.svg';

export default class PrevArrow extends React.PureComponent {
  clickHandler(options, e) {
    if (e) {
      e.preventDefault();
    }
    this.props.clickHandler(options, e);
  }

  render() {
    const prevClasses = {
      [styles.slider__arrow]: true,
      [styles.slider__arrowPrev]: true,
    };
    let prevHandler = this.clickHandler.bind(this, { message: 'previous' });

    if (
      !this.props.infinite
      && (this.props.currentSlide === 0
        || this.props.slideCount <= this.props.slidesToShow)
    ) {
      prevClasses[styles.slider__arrowDisabled] = true;
      prevHandler = null;
    }

    const prevArrowProps = {
      key: '0',
      'data-role': 'none',
      className: classnames(prevClasses),
      style: { display: 'block' },
      onClick: prevHandler,
    };
    const customProps = {
      currentSlide: this.props.currentSlide,
      slideCount: this.props.slideCount,
    };
    let prevArrow;

    if (this.props.prevArrow) {
      prevArrow = React.cloneElement(this.props.prevArrow, {
        ...prevArrowProps,
        ...customProps,
      });
    } else {
      prevArrow = (
        <button key="slide-prev" type="button" {...prevArrowProps}>
          {' '}
          <Chevron />
          Previous
        </button>
      );
    }

    return prevArrow;
  }
}
