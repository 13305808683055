import React from "react"
import { useState, useRef, useEffect } from "react"
import cx from "classnames"
import { GatsbyImage } from "gatsby-plugin-image"
import FocusTrap from "focus-trap-react"
import getImageData from "@hooks/getImageData"
import useFade from "@hooks/useFade"
import useKeyPressEvent from "@hooks/useKeyPressEvent"
import * as styles from "./Popup.module.scss"

const Popup = props => {
  const {
    popupEnable,
    popupImage,
    popupImageAltText,
    popupBodyHtml,
    popupCtaBodyHtml,
  } = props
  const [close, setClose] = useState(true)
  const [isFading, hasFaded] = useFade(close, 1000)
  const modalRef = useRef(null)
  useKeyPressEvent("Escape", () => setClose(true), [setClose])
  const imageData = getImageData({ imageFile: popupImage })

  const modalOnClickHandler = e => {
    if (modalRef.current && modalRef.current.contains(e.target)) {
      return
    }
    setClose(true)
    sessionStorage.setItem("modal-closed", "true")
  }

  const closeModal = () => {
    setClose(true)
    sessionStorage.setItem("modal-closed", "true")
  }

  useEffect(() => {
    if (close) {
      document.body.classList.remove("modal")
    } else {
      document.body.classList.add("modal")
    }

    return function cleanup() {
      document.body.classList.remove("modal")
    }
  }, [close])

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (sessionStorage.length >= 0) {
        setClose(!!sessionStorage.getItem("modal-closed"))
      }
    }

    return null
  }, [])

  return !popupEnable || close ? (
    <React.Fragment />
  ) : (
    <section
      role="contentinfo"
      onClick={modalOnClickHandler}
      className={cx(styles.popup, {
        [styles.popupIsFading]: isFading,
        [styles.popupHasFaded]: hasFaded,
      })}
    >
      <FocusTrap
        active={!close}
        focusTrapOptions={{
          clickOutsideDeactivates: true,
          initialFocus: "#close",
        }}
      >
        <div
          className={styles.popup__modal}
          role="dialog"
          aria-modal="true"
          ref={modalRef}
        >
          <div className={styles.popup__content}>
            {popupBodyHtml && (
              <div
                className={cx(styles.popup__body)}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: popupBodyHtml,
                }}
              />
            )}
            {popupCtaBodyHtml && (
              <div
                className={cx(styles.popup__cta)}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: popupCtaBodyHtml,
                }}
              />
            )}
          </div>
          {imageData && imageData[0] && imageData[0].node && (
            <GatsbyImage
              className={cx(styles.popup__image)}
              image={imageData[0].node.gatsbyImageData}
              alt={popupImageAltText}
            />
          )}
          <button
            id="close"
            className={cx(styles.popup__close)}
            onClick={closeModal}
          >
            <svg
              width="23"
              height="24"
              viewBox="0 0 23 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21.7225 22.2222L1.27801 1.77778"
                stroke="currentColor"
                strokeWidth="1.5"
              />
              <path
                d="M21.7222 1.77778L1.27779 22.2222"
                stroke="currentColor"
                strokeWidth="1.5"
              />
            </svg>
            <span className={cx(styles.popup__closeText)}>Close</span>
          </button>
        </div>
      </FocusTrap>
    </section>
  )
}

export default Popup
