// extracted by mini-css-extract-plugin
export var systemsection = "SystemSection-module--systemsection--2mCv7";
export var systemsection__btpattern = "SystemSection-module--systemsection__btpattern--25mfN";
export var systemsection__image = "SystemSection-module--systemsection__image--gb471";
export var systemsection__cards = "SystemSection-module--systemsection__cards--3Ayiv";
export var systemsection__card = "SystemSection-module--systemsection__card--2tWY5";
export var systemsection__cardtitle = "SystemSection-module--systemsection__cardtitle--2VFkB";
export var systemsection__card1 = "SystemSection-module--systemsection__card--1--2Bu1F";
export var systemsection__card2 = "SystemSection-module--systemsection__card--2--2Y8Be";
export var systemsection__card3 = "SystemSection-module--systemsection__card--3--2KshH";
export var systemsection__card4 = "SystemSection-module--systemsection__card--4--1lpDK";
export var systemsection__card5 = "SystemSection-module--systemsection__card--5--3-9QQ";
export var systemsection__cardAnd = "SystemSection-module--systemsection__card--and--2Oz2b";
export var systemsection__icon = "SystemSection-module--systemsection__icon--2xp1C";
export var fadeOutAnimation = "SystemSection-module--fadeOutAnimation--34nLj";
export var fadeInAnimation = "SystemSection-module--fadeInAnimation--1cMbe";