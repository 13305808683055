// extracted by mini-css-extract-plugin
export var slider__controls = "Slider-module--slider__controls--2vsKC";
export var slider__arrowDisabled = "Slider-module--slider__arrow--disabled--v7Au7";
export var slider__arrow = "Slider-module--slider__arrow--25IJw";
export var slider__hidden = "Slider-module--slider__hidden--2TOWs";
export var slider__arrowNext = "Slider-module--slider__arrow--next--2_u4s";
export var slider__arrowPrev = "Slider-module--slider__arrow--prev--1Dj3R";
export var slider__dots = "Slider-module--slider__dots--U7Goi";
export var slider__active = "Slider-module--slider__active--o-8tJ";
export var slider__dotted = "Slider-module--slider__dotted--2-BlP";
export var slider__slider = "Slider-module--slider__slider--2-wzp";
export var slider__list = "Slider-module--slider__list--1zpij";
export var dragging = "Slider-module--dragging--3zxxA";
export var slider__track = "Slider-module--slider__track--mBQvI";
export var slider__loading = "Slider-module--slider__loading--38nfU";
export var slider__slide = "Slider-module--slider__slide--300mH";
export var slider__initialized = "Slider-module--slider__initialized--2j0Q5";
export var slider__vertical = "Slider-module--slider__vertical--3MO7Y";
export var slider__current = "Slider-module--slider__current--1MlMh";
export var slider__cloned = "Slider-module--slider__cloned--3DiIz";
export var slider__center = "Slider-module--slider__center--2_gJ6";
export var fadeOutAnimation = "Slider-module--fadeOutAnimation--62wrE";
export var fadeInAnimation = "Slider-module--fadeInAnimation--1wnms";