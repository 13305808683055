import React from 'react';
import * as styles from './SectionTitle.module.scss';

const SectionTitle = ({ children }) => (
  <h2 className={styles.sectiontitle}>
    {children}
  </h2>
);

export default SectionTitle;
