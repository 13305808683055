import React from 'react';
import cx from 'classnames';
import { Link } from 'gatsby';
import * as styles from './Button.module.scss';

const Button = ({
  href, children, type, ...rest
}) => {
  if (!href) {
    return <React.Fragment />;
  }
  if (href.startsWith('http') || href.startsWith('//')) {
    return (
      <a
        href={href}
        {...rest}
        className={cx(styles.button, {
          [styles.buttonPrimary]: type === 'primary',
          [styles.buttonSecondary]: type === 'secondary',
        })}
      >
        {children}
      </a>
    );
  }

  return (
    <Link
      className={cx(styles.button, {
        [styles.buttonPrimary]: type === 'primary',
      })}
      to={href}
      {...rest}
    >
      {children}
    </Link>
  );
};

export default Button;
