import React from 'react';
import cx from 'classnames';
import * as styles from './SectionIntro.module.scss';

const SectionIntro = ({ intro, align }) => (
  <div
    className={cx(styles.sectionintro, {
      [styles.sectionintroLeft]: align === 'left',
      [styles.sectionintroRight]: align === 'right',
      [styles.sectionintroCenter]: align === 'center',
    })}
      // eslint-disable-next-line react/no-danger
    dangerouslySetInnerHTML={{
      __html: intro,
    }}
    itemProp="intro"
  />
);

export default SectionIntro;
