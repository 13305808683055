import React from 'react';
import cx from 'classnames';
import { GatsbyImage } from 'gatsby-plugin-image';
import Button from '@components/Button';
import SignUpForm from '@components/SignUpForm';
import * as styles from './Hero.module.scss';

const Hero = (props) => {
  const {
    title, intro, ctas, image, formHeader, alt, formSetup, postFormSubmit,
  } = props;

  return (
    <div className={cx(styles.hero)}>
      <div className={styles.hero__max}>
        <div className={cx(styles.hero__colLeft)}>
          <div className={cx(styles.hero__bgpattern)}>
            <div className={cx(styles.hero__top)}>
              <div className={cx(styles.hero__content, styles.hero__contentLeft)}>
                {title && (
                  <h1 className={cx(styles.hero__title)}>
                    {title}
                  </h1>
                )}
                {ctas && (
                  <ul className={cx(styles.hero__ctas)}>
                    {ctas.map((cta) => {
                      const { link, buttonText } = cta;
                      return (
                        <li className={cx(styles.hero__cta)} key={link}>
                          <Button href={link} type="primary" key={link}>
                            {buttonText}
                          </Button>
                        </li>
                      );
                    })}
                  </ul>
                )}
              </div>
            </div>
            {image && image.node && (
              <GatsbyImage
                className={cx(styles.hero__image, styles.hero__imageMobile)}
                image={image.node.gatsbyImageData}
                loading="eager"
                backgroundColor="#6B3775"
                alt={alt}
              />
            )}
          </div>
        </div>
        <div className={cx(styles.hero__colRight)}>
          {image && image.node && (
            <div className={cx(styles.hero__imgWrapper)}>
              <GatsbyImage
                className={cx(styles.hero__image, styles.hero__imageDesktop)}
                image={image.node.gatsbyImageData}
                loading="eager"
                backgroundColor="#6B3775"
                alt={alt}
              />
            </div>
          )}
        </div>
      </div>
      <div className={styles.hero__max}>
        <div className={cx(styles.hero__colLeft)}>
          <div className={cx(styles.hero__content, styles.hero__contentLeft)}>
            {intro && (
              <div
                className={cx(styles.hero__intro)}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: intro,
                }}
                itemProp="intro"
              />
            )}
          </div>
        </div>
        <div className={cx(styles.hero__colRight)}>
          <div className={cx(styles.hero__content, styles.hero__contentRight)}>
            {formHeader && (
              <h2 className={cx(styles.hero__formtitle)}>{formHeader}</h2>
            )}
            {formSetup && (
              <SignUpForm {...formSetup} id={`${formSetup.formId}id`} postSubmitMessage={postFormSubmit} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
