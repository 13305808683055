// extracted by mini-css-extract-plugin
export var popup = "Popup-module--popup--kdmrT";
export var fadeInAnimation = "Popup-module--fadeInAnimation--1IFjR";
export var popupIsFading = "Popup-module--popup--isFading--127lL";
export var fadeOutAnimation = "Popup-module--fadeOutAnimation--31kSl";
export var popupHasFaded = "Popup-module--popup--hasFaded--3MzVB";
export var popup__modal = "Popup-module--popup__modal--rajMT";
export var popup__content = "Popup-module--popup__content--2D2VE";
export var popup__body = "Popup-module--popup__body--3uXZ6";
export var popup__cta = "Popup-module--popup__cta--2Xo-p";
export var popup__image = "Popup-module--popup__image--DVXnI";
export var popup__close = "Popup-module--popup__close--JABmd";
export var popup__closeText = "Popup-module--popup__close-text--1s_UH";