import React from 'react';
import cx from 'classnames';
import { GatsbyImage } from 'gatsby-plugin-image';
import Gutters from '@components/Gutters';
import MaxContent from '@components/MaxContent';
import SectionTitle from '@components/SectionTitle';
import SectionIntro from '@components/SectionIntro';
import getImageData from '@hooks/getImageData';
import * as styles from './SystemSection.module.scss';

const SystemSection = (props) => {
  const {
    title, intro, systems,
  } = props;

  const finalSystems = [];

  if (systems) {
    systems.forEach((system) => {
      const { photo } = system;
      const [systemImage] = getImageData({ imageFile: photo });

      finalSystems.push({
        ...system,
        photo: systemImage,
      });
    });
  }

  const systemCount = finalSystems.length;

  return (
    <section className={styles.systemsection} aria-label={title} role="contentinfo">
      <div className={styles.systemsection__btpattern} />
      <MaxContent>
        <Gutters>
          {title && (
            <SectionTitle>{title}</SectionTitle>
          )}
          {intro && (
            <SectionIntro intro={intro} align="center" />
          )}
          {finalSystems && (
            <ul className={styles.systemsection__cards}>
              {finalSystems.map((plan, i) => {
                const { photo, name, alt } = plan;
                return (
                  <React.Fragment>
                    {i === (systemCount - 1) && (
                      <li
                        key={name}
                        className={cx(styles.systemsection__card, styles.systemsection__cardAnd, styles[`systemsection__card${(systemCount)}`])}
                      >
                        <p className={cx(styles.systemsection__cardtitle)}>
                          and
                        </p>
                      </li>
                    )}
                    <li
                      key={name}
                      className={cx(styles.systemsection__card, styles[`systemsection__card${(systemCount)}`])}
                    >
                      {photo && photo.node && (
                        <GatsbyImage
                          className={cx(styles.systemsection__image)}
                          image={photo.node.gatsbyImageData}
                          alt={alt}
                        />
                      )}
                      <p className={cx(styles.systemsection__cardtitle)}>{name}</p>
                    </li>
                  </React.Fragment>
                );
              })}
            </ul>
          )}
        </Gutters>
      </MaxContent>
    </section>
  );
};

export default SystemSection;
