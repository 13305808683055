/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

export default function getImageData({ imageFile }) {
  const [imageData, setImageData] = useState();

  const data = useStaticQuery(graphql`
    query AllImages {
      allImageSharp {
        edges {
          node {
            fluid {
              originalName
            }
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
            )
          }
        }
      }
    }
  `);

  useEffect(() => {
    const finalImage = data && data.allImageSharp && data.allImageSharp.edges && data.allImageSharp.edges.find((infoImage) => `/img/${infoImage.node.fluid.originalName}` === imageFile);
    if (finalImage) {
      setImageData(finalImage);
    }
  }, [data, imageFile]);

  return [imageData];
}
