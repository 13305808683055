import React from 'react';
import cx from 'classnames';
import { GatsbyImage } from 'gatsby-plugin-image';
import getImageData from '@hooks/getImageData';
import Button from '@components/Button';
import MaxContent from '@components/MaxContent';
import SectionTitle from '@components/SectionTitle';
import SectionIntro from '@components/SectionIntro';
import Slider from '@components/Slider';
import * as styles from './ResourceSection.module.scss';

const ResourceSection = (props) => {
  const {
    title, intro, slideshow, sidebar, buttonlink, buttontext,
  } = props;

  const finalSlides = [];

  if (slideshow) {
    slideshow.forEach((slide) => {
      const { slide: photo } = slide;
      const [slideImage] = getImageData({ imageFile: photo });

      finalSlides.push({
        ...slide,
        photo: slideImage,
      });
    });
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <section className={styles.resourcesection} aria-label={title} role="contentinfo">
      <div className={styles.resourcesection__btpattern} />
      <MaxContent>
        <div className={styles.resourcesection__content}>
          {title && (
            <SectionTitle>{title}</SectionTitle>
          )}
        </div>
        <div className={styles.resourcesection__content}>
          <div className={styles.resourcesection__contentLeft}>
            {intro && (
              <SectionIntro intro={intro} align="left" />
            )}
            <Slider {...settings}>
              {finalSlides.map((slide) => {
                const { photo, alt } = slide;
                return (
                  <div>
                    {photo && photo.node && (
                      <GatsbyImage
                        className={cx(styles.resourcesection__image)}
                        image={photo.node.gatsbyImageData}
                        alt={alt}
                      />
                    )}
                  </div>
                );
              })}
            </Slider>
          </div>
          <div className={styles.resourcesection__contentRight}>
            {sidebar && (
              <SectionIntro intro={sidebar} align="left" />
            )}
            {buttonlink && buttontext && (
              <Button href={buttonlink} type="secondary" key={buttonlink}>
                {buttontext}
              </Button>
            )}
          </div>
        </div>
      </MaxContent>
    </section>
  );
};

export default ResourceSection;
